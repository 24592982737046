.selectedItemsContainer {
  width: 100%;
  border-top: 1px solid #ebebeb;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
}

.tableAssignmentGroups,
.tableAssignmentForms {
  max-height: 35vh !important;
  overflow: auto;
}

.selectedItemsContainer .SIBadgesContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  max-width: 100%;
  flex-wrap: wrap;
  max-height: 12vh;
  overflow: auto;

  margin-top: 1rem;
}

.selectedItemsContainer h3 {
  font-size: 20px;
  line-height: 24px;
  color: #7D7D7D;
}

.selectedItemsBadgeContainer {
  width: auto;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.selectedItemsBadgeContainer span {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}